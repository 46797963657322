const heroComp = () => import("./hero/index.vue");
const MetroItems = () => import("./metroItems/index.vue");
const AddGatewayFlow = () => import("./addGatewayFlow/index.vue");
const Plugins = () => import("./plugins/index.vue");
const Customers = () => import("./customers/index.vue");

export default {
  name: "home",
  components: {
    heroComp,
    MetroItems,
    AddGatewayFlow,
    Plugins,
    Customers
  },
  mixins: [],
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
